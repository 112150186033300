import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import Layout from "../components/Layout";
import DefaultInput from "../components/Inputs/DefaultInput";
import RoundedButton from "../components/Buttons/RoundedButton";
import styles from "../styles/ForgotPassword.module.scss";
import { sendToast, showAllErrors } from "../utils/helpers";
import {
  ForgotPasswordObject,
  updatePassword,
} from "../store/passwordReset/actions";
import { useAppDispatch } from "../store";
import {
  selectErrors,
  selectPasswordResetLoading,
  selectPasswordUpdateSuccess,
} from "../store/passwordReset/selectors";
import FullScreenLoading from "../components/Loading/FullScreenLoading";
import { resetSuccessfulPasswordUpdate } from "../store/passwordReset/reducer";
import Footer from "../components/Home/internal/Footer";

const PasswordReset = () => {
  const dispatch = useAppDispatch();
  const [passwordRef, setPasswordRef] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");

  const isLoading = useSelector((state) => selectPasswordResetLoading(state));
  const successfulPasswordUpdate = useSelector((state) =>
    selectPasswordUpdateSuccess(state)
  );
  const errors = useSelector((state) => selectErrors(state));

  useEffect(() => {
    if (errors) {
      showAllErrors(errors);
    }
  }, [errors]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const ref = urlSearchParams.get("reset_token");

      if (!ref) {
        sendToast(
          "If you have submitted a password reset, check your email and click on the link.",
          { type: "error" }
        );
      } else {
        setPasswordRef(ref);
      }
    }

    return () => {
      dispatch(resetSuccessfulPasswordUpdate());
    };
  }, []);

  const submitPasswordChange = () => {
    if (newPassword === "" || passwordRepeat === "") {
      sendToast("Please enter a new password", { type: "error" });
      return;
    }
    if (newPassword !== passwordRepeat) {
      sendToast("The passwords you entered do not match. Please try again.", {
        type: "error",
      });
      return;
    }
    if (newPassword.length < 6) {
      sendToast("Please enter a password of more than 6 characters", {
        type: "error",
      });
      return;
    }
    if (!passwordRef) {
      sendToast("There is no token, please re-click the link in your email.", {
        type: "error",
      });
      return;
    }
    const forgotPasswordObject = {
      password: newPassword,
      reset_token: passwordRef,
    } as ForgotPasswordObject;

    dispatch(updatePassword(forgotPasswordObject));
  };

  return (
    <Layout
      noIndex
      hideFooter
      canonical="https://bidddy.com/password-reset/"
      image="https://imgix.cosmicjs.com/1ea993f0-de97-11eb-a12b-17ba28648e41-HomeOgImage.png"
      description="Bidddy is a tool that allows you to run comment driven auctions on social media. This provides businesses of any size a new way to grow sales and online presence."
      title="Password Reset - Bidddy | The World's First Automated Auctions for Social Media"
    >
      <div className={styles.forgotPasswordContainer}>
        <h1>Reset Your Password</h1>
        <p>
          {successfulPasswordUpdate ? "" : "Enter your new password below."}
        </p>
        {!successfulPasswordUpdate && (
          <div className={styles.formContainer}>
            <label>New Password</label>
            <DefaultInput
              name="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Password"
              type="password"
            />
            <label className="mt-2">Re-type New Password</label>
            <DefaultInput
              name="password"
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)}
              placeholder="Re-type Password"
              type="password"
            />
            <RoundedButton
              disabled={newPassword === "" || passwordRepeat === ""}
              onClick={() => submitPasswordChange()}
            >
              Update Password
            </RoundedButton>
          </div>
        )}
        {successfulPasswordUpdate && (
          <div className={styles.successContainer}>
            <div
              className={cn([
                {
                  [styles.circleLoader]: isLoading,
                  [styles.loadComplete]: !isLoading,
                },
                styles.loaderContainer,
              ])}
            >
              <div className={cn([styles.checkmark, styles.draw])} />
            </div>
            <p>You have successfully updated your password!</p>
            <RoundedButton onClick={() => navigate("/signin/")}>
              Sign In
            </RoundedButton>
          </div>
        )}
      </div>
      {isLoading && <FullScreenLoading />}
      <Footer />
    </Layout>
  );
};

export default PasswordReset;
